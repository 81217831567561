<template>
  <el-dialog
    :visible.sync="dialogVisible"
    ref="ruleForm"
    width="390px"
    class="loginDialog"
    :before-close="handleClose"
  >
    <div class="title">{{ $t("navbar.title") }}</div>
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="name">
        <label slot="label">{{ $t("navbar.name") }}</label>
        <el-input
          v-model="form.name"
          auto-complete="off"
          @keyup.enter.native="submitForm('form')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="email">
        <label slot="label">{{ $t("navbar.email") }}</label>
        <el-input
          type="email"
          v-model="form.email"
          auto-complete="off"
          @keyup.enter.native="submitForm('form')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="content" class="content">
        <label slot="label">{{ $t("navbar.content") }}</label>
        <el-input
          type="textarea"
          v-model="form.content"
          auto-complete="off"
          @keyup.enter.native="submitForm('form')"
          resize="none"
          rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button class="loginbtn" @click="submitForm('form')">
      {{ $t("navbar.sendBtn") }}
    </el-button>
  </el-dialog>
</template>

<script>
export default {
  name: "ContactDialog",
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        email: "",
        content: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("navbar.pleaseEnterName"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("navbar.pleaseEnterEmail"),
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("navbar.pleaseEnterContent"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.$on("open", function () {
      this.dialogVisible = true;
    });
  },
  methods: {
    keyboardEvent(e) {
      if (e.which === 13) {
        // run your code
      }
    },
    handleClose() {
      this.form.name = "";
      this.form.email = "";
      this.form.content = "";
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      this.dialogVisible = false;
      // let loadingInstance = Loading.service({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-loading-spinner",
      //   background: "rgba(0, 0, 0, 0.7)",
      //   target: ".loginDialog",
      // });
      // var postform = {
      //   account: this.form.account,
      //   passwd: md5(this.form.passwd),
      // };
      // axios
      //   .post("/api/login", postform)
      //   .then((res) => {
      //     // console.log(res.data);
      //     if (res.data.result == "success") {
      //       localStorage.setItem("account", res.data.account);
      //       localStorage.setItem("status", res.data.status);
      //       this.$message({
      //         message: this.$t("navbar.login.loginSuccess"),
      //         type: "success",
      //         customClass: "message",
      //         showClose: true,
      //       });
      //       this.$nextTick(() => {
      //         loadingInstance.close();
      //       });
      //       this.reInitChatra();
      //       this.handleClose(true);
      //     } else {
      //       this.$message({
      //         message: this.$t("navbar.login.loginFail"),
      //         type: "error",
      //         customClass: "message",
      //         showClose: true,
      //       });
      //       this.$nextTick(() => {
      //         loadingInstance.close();
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.$nextTick(() => {
      //       loadingInstance.close();
      //     });
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginDialog {
  @include flex-set();
  :deep(.el-dialog) {
    // height: 293px !important;
    border-radius: 20px;
    background: #3d3d3d;
  }
  :deep(.el-dialog__headerbtn) {
    display: none;
  }
  :deep(.el-dialog__header) {
    display: none;
  }
  :deep(.el-dialog__body) {
    padding: 0;
    @include flex-set();
    border: #f0c856 1px solid;
    border-radius: 20px;
    .title {
      margin: 32px 0;
      @include font-set(24px, #f0c856, 600);
      text-align: center;
      width: 100%;
    }
    .el-form {
      @include flex-set();
      .el-form-item {
        width: 100%;
        margin-bottom: 16px;
        @include flex-set();
        .el-form-item__label {
          width: 54px !important;
          padding: 0;
          @include font-set(18px, $white);
        }
        .el-form-item__content {
          margin-left: 8px !important;
          border-bottom: #fff 1px solid;
          .el-input {
            display: block;
            width: 235px;
            .el-input__inner {
              padding: 0 10px;
              // height: 18px;
              // height: 100%;
              @include font-set(18px, $white);
              border: none;
              background: #3d3d3d;
            }
          }
          .el-form-item__error {
            left: unset;
            right: 10px;
          }
        }
      }
      .content {
        .el-form-item__label {
          width: 100% !important;
          float: none !important; // 取消浮动
          word-break: break-word !important; // 支持单词截断换行
          text-align: left;
          margin-left: 47px;
        }
        .el-form-item__content {
          width: 77% !important;
          border-bottom: unset;
          margin-top: 10px;
          .el-textarea {
            .el-textarea__inner {
              background: rgba(255, 255, 255, 0.1);
              @include font-set(18px);
              border: #ffffff 1px solid;
            }
          }
        }
      }
    }
    .loginbtn {
      margin: 14px 0 32px;
      width: 64px;
      height: 30px;
      background: #f0c856;
      border-radius: 5px;
      @include font-set(16px, $black);
      text-align: center;
      line-height: 30px;
      padding: 0 !important;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
}

:deep(.el-message) {
  min-width: 200px !important;
}
</style>
