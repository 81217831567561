<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <NavbarComponent
      :navbarClass="navbarClass"
      @scrollToFrame="scrollToFrame"
    />
    <swiper
      class="swiper"
      :options="swiperOption"
      ref="swiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide><FrameComponent1 /></swiper-slide>
      <swiper-slide><FrameComponent2 /></swiper-slide>
      <swiper-slide><FrameComponent3 /></swiper-slide>
      <swiper-slide><FrameComponent4 /></swiper-slide>
    </swiper>
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import NavbarComponent from "@/components/NavbarComponent.vue";
import FrameComponent1 from "@/components/FrameComponent1.vue";
import FrameComponent2 from "@/components/FrameComponent2.vue";
import FrameComponent3 from "@/components/FrameComponent3.vue";
import FrameComponent4 from "@/components/FrameComponent4.vue";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
    Swiper,
    SwiperSlide,
    NavbarComponent,
    FrameComponent1,
    FrameComponent2,
    FrameComponent3,
    FrameComponent4,
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // onSlideChangeEnd: function () {
        //   this.onSlideChange();
        // },
      },
      currentSwiperIndex: 0,
      navbarClass: "frame1",
    };
  },
  watch: {
    currentSwiperIndex(newValue) {
      switch (newValue) {
        case 0:
          this.navbarClass = "frame1";
          break;

        case 1:
          this.navbarClass = "frame2";
          break;

        case 2:
        case 3:
          this.navbarClass = "frame3";
          break;

        default:
          this.navbarClass = "";
          break;
      }
    },
  },
  methods: {
    onSlideChange() {
      // console.log(this.$refs.swiper.$swiper.activeIndex);
      this.currentSwiperIndex = this.$refs.swiper.$swiper.activeIndex;
    },
    scrollToFrame(frame) {
      this.$refs.swiper.$swiper.slideTo(frame);
    },
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-width: 1366px;
  min-height: 768px;
  overflow: scroll;
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      width: 100%;
      height: 100%;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}
</style>
