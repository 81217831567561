export default {
  title: "联系我们",
  name: "称呼：",
  email: "信箱：",
  content: "讯息内容：",
  sendBtn: "送出",
  pleaseEnterName: "请填写称呼栏位",
  pleaseEnterEmail: "请填写信箱栏位",
  pleaseEnterContent: "请填写讯息内容栏位",
  freeplay: "免费试玩",
  frame1: "关于我们",
  frame2: "特色说明",
  frame3: "包网平台",
  frame4: "合作厂商",
};
