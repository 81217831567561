import Vue from "vue";
import VueI18n from "vue-i18n";

import eng from "./lang/eng";
import zht from "./lang/zht";
import zhs from "./lang/zhs";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: zht,
  fallbackLocale: "zht",
  messages: { zht, zhs, eng },
  silentTranslationWarn: true,
});

export default i18n;
