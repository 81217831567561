export default {
  bigTitle: "滿足使用者所需是唯一的原則",
  smallTitle: "運用最新技術和靈活的創意<br>為您提供最快速便捷的遊戲包網服務",
  phoneText: "手機版介面<br>震撼來襲<br>讓您隨時操控<br>玩轉投注不設限",
  imageText1: "全球合作機構數量",
  imageText2: "運營總帶寬",
  imageText3: "服務的客戶",
  imageText4: "全球會員數",
  footerText: " Copyright © 2022 版權所有. All rights reserved.",
};
