<template>
  <div class="framecontainer">
    <div class="title-text-box">
      <div class="bigtext">{{ $t("frame3.bigTitle") }}</div>
      <div class="smalltext">{{ $t("frame3.smallTitle") }}</div>
    </div>
    <div class="middle_text">{{ $t("frame3.nineSpecialPointText") }}</div>
    <div class="bararea">
      <div class="bar" v-for="(element, index) in barData" :key="index">
        <img :src="element.icon" class="icon" />
        <div class="text">{{ $t(element.text) }}</div>
      </div>
    </div>
    <div class="bottomtitle">{{ $t("frame3.bottomTitle") }}</div>
    <div class="bottom_medium_group">
      <div
        class="image_text_group"
        v-for="(element, index) in bottomMediumData"
        :key="index"
      >
        <div class="imagebox">
          <img :src="element.img" class="img" />
        </div>
        <div class="big_text">{{ $t(element.bigText) }}</div>
        <div class="small_text">{{ $t(element.smallText) }}</div>
      </div>
    </div>
    <div class="bottomcardarea">
      <div class="card" v-for="(element, index) in cardData" :key="index">
        <div class="left">
          <div class="title">{{ $t(element.title) }}</div>
          <div class="description" v-html="$t(element.description)"></div>
        </div>
        <div class="right">
          <div class="group">
            <div class="imagebox">
              <img :src="element.icon1" class="img" />
            </div>
            <div class="text">{{ $t(element.text1) }}</div>
          </div>
          <div class="group">
            <div class="imagebox">
              <img :src="element.icon2" class="img" />
            </div>
            <div class="text">{{ $t(element.text2) }}</div>
          </div>
          <div class="group">
            <div class="imagebox">
              <img :src="element.icon3" class="img" />
            </div>
            <div class="text">{{ $t(element.text3) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameComponent3",
  data() {
    return {
      barData: [
        {
          icon: require("@/assets/images/Icon/P3/Feature/01.svg"),
          text: "frame3.barText1",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/02.svg"),
          text: "frame3.barText2",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/03.svg"),
          text: "frame3.barText3",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/04.svg"),
          text: "frame3.barText4",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/05.svg"),
          text: "frame3.barText5",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/06.svg"),
          text: "frame3.barText6",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/07.svg"),
          text: "frame3.barText7",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/08.svg"),
          text: "frame3.barText8",
        },
        {
          icon: require("@/assets/images/Icon/P3/Feature/09.svg"),
          text: "frame3.barText9",
        },
      ],
      bottomMediumData: [
        {
          img: require("@/assets/images/Icon/P3/Special/01.svg"),
          bigText: "frame3.bottomMediumGroupBigText1",
          smallText: "frame3.bottomMediumGroupSmallText1",
        },
        {
          img: require("@/assets/images/Icon/P3/Special/02.svg"),
          bigText: "frame3.bottomMediumGroupBigText2",
          smallText: "frame3.bottomMediumGroupSmallText2",
        },
        {
          img: require("@/assets/images/Icon/P3/Special/03.svg"),
          bigText: "frame3.bottomMediumGroupBigText3",
          smallText: "frame3.bottomMediumGroupSmallText3",
        },
      ],
      cardData: [
        {
          title: "frame3.card1.title",
          description: "frame3.card1.description",
          icon1: require("@/assets/images/Icon/P3/Fast/01.svg"),
          text1: "frame3.card1.text1",
          icon2: require("@/assets/images/Icon/P3/Fast/02.svg"),
          text2: "frame3.card1.text2",
          icon3: require("@/assets/images/Icon/P3/Fast/03.svg"),
          text3: "frame3.card1.text3",
        },
        {
          title: "frame3.card2.title",
          description: "frame3.card2.description",
          icon1: require("@/assets/images/Icon/P3/Fast/01.svg"),
          text1: "frame3.card2.text1",
          icon2: require("@/assets/images/Icon/P3/Fast/02.svg"),
          text2: "frame3.card2.text2",
          icon3: require("@/assets/images/Icon/P3/Fast/03.svg"),
          text3: "frame3.card2.text3",
        },
        {
          title: "frame3.card3.title",
          description: "frame3.card3.description",
          icon1: require("@/assets/images/Icon/P3/Fast/01.svg"),
          text1: "frame3.card3.text1",
          icon2: require("@/assets/images/Icon/P3/Fast/02.svg"),
          text2: "frame3.card3.text2",
          icon3: require("@/assets/images/Icon/P3/Fast/03.svg"),
          text3: "frame3.card3.text3",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.framecontainer {
  width: 100%;
  height: 100%;
  background-color: #f0c856;
  .title-text-box {
    width: 100%;
    max-height: 25%;
    margin: 144px 0 32px;
    @include flex-set($align: unset);
    text-align: center;
    .bigtext {
      width: 100%;
      margin-bottom: 15px;
      @include font-set(42px, $white, 600);
    }
    .smalltext {
      width: 100%;
      @include font-set(22px, #3d3d3d);
    }
  }
  .middle_text {
    @include font-set(36px, #3d3d3d, 500);
    height: 50px;
    line-height: 50px;
    margin-bottom: 16px;
  }
  .bararea {
    width: 65%;
    margin: 0 auto;
    @include flex-set();
    .bar {
      width: 28%;
      background-color: #3d3d3d;
      border-radius: 8px;
      // max-width: 314px;
      height: 66px;
      @include flex-set();
      padding: 0 10px;
      margin: 0 15px 15px 0;
      .icon {
        height: 50px;
        width: 50px;
      }
      .text {
        width: calc(100% - 60px);
        margin-left: 10px;
        @include font-set(14px);
        text-align: left;
      }
      &:nth-child(3n) {
        margin: 0 0 15px;
      }
    }
  }
  .bottomtitle {
    width: 100%;
    text-align: center;
    margin: 22px 0 32px;
    @include font-set(28px, #3d3d3d, 600);
  }
  .bottom_medium_group {
    width: 55%;
    margin: 0 auto;
    @include flex-set();
    .image_text_group {
      @include flex-set();
      width: 33%;
      max-width: 150px;
      height: 118px;
      margin-right: 64px;
      .imagebox {
        width: 100%;
        margin-bottom: 16px;
        @include flex-set();
        .img {
          width: 60px;
          height: 60px;
        }
      }
      .big_text {
        width: 100%;
        @include font-set(18px, #3d3d3d);
        text-align: center;
        margin-bottom: 2px;
      }
      .small_text {
        width: 100%;
        @include font-set(12px, #3d3d3d);
        text-align: center;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .bottomcardarea {
    width: 80%;
    @include flex-set();
    margin: 46px auto 0;
    .card {
      @include flex-set($justify: space-between, $wrap: unset);
      width: 30%;
      max-width: 366px;
      height: 94px;
      background-image: linear-gradient(
        73deg,
        #50504b 31%,
        rgba(255, 255, 255, 0.2) 31.5%
      );
      border: 1px solid #50504b;
      border-radius: 10px;
      margin-right: 10px;
      padding: 8px;
      &:last-child {
        margin-right: 0;
      }
      .left {
        width: 30%;
        @include flex-set($justify: space-between);
        flex-direction: column;
        height: 100%;
        .title {
          width: 100%;
          text-align: left;
          @include font-set(32px, #f0c856);
        }
        .description {
          width: 100%;
          text-align: left;
          line-height: 16px;
          @include font-set(12px);
        }
      }
      .right {
        width: 67%;
        @include flex-set();
        .group {
          width: 30%;
          max-width: 70px;
          height: 80px;
          @include flex-set();
          .imagebox {
            @include flex-set();
            width: 50px;
            height: 50px;
            background: $white;
            border: 1.5px solid #50504b;
            border-radius: 50%;
            .img {
              width: 32px;
              height: 32px;
            }
          }
          .text {
            width: 100%;
            @include font-set(14px, #3d3d3d);
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .framecontainer {
    .title-text-box {
      margin: 112px 0 16px;
    }
    .middle_text {
      @include font-set(30px, #3d3d3d, 500);
      height: 44px;
      line-height: 44px;
      margin-bottom: 8px;
    }
    .bottomcardarea {
      margin: 32px auto 0;
    }
  }
}
@media screen and (max-width: 1366px) {
  .framecontainer {
    .title-text-box {
      margin: 100px 0 8px;
    }
    .bararea {
      width: 80%;
      .bar {
        margin: 0 10px 10px 0;
        &:nth-child(3n) {
          margin: 0 0 10px;
        }
      }
    }
    .bottomtitle {
      width: 100%;
      text-align: center;
      margin: 5px 0 15px;
      @include font-set(24px, #3d3d3d, 600);
    }
    .bottomcardarea {
      width: 90%;
      margin: 23px auto 0;
    }
  }
}
</style>
