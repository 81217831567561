export default {
  title: "聯繫我們",
  name: "稱呼：",
  email: "信箱：",
  content: "訊息內容：",
  sendBtn: "送出",
  pleaseEnterName: "請填寫稱呼欄位",
  pleaseEnterEmail: "請填寫信箱欄位",
  pleaseEnterContent: "請填寫訊息內容欄位",
  freeplay: "免費試玩",
  frame1: "關於我們",
  frame2: "特色說明",
  frame3: "包網平台",
  frame4: "合作廠商",
};
