import frame1 from "./zhs/frame1";
import frame2 from "./zhs/frame2";
import frame3 from "./zhs/frame3";
import frame4 from "./zhs/frame4";
import navbar from "./zhs/navbar";

export default {
  navbar: {
    ...navbar,
  },
  frame1: {
    ...frame1,
  },
  frame2: {
    ...frame2,
  },
  frame3: {
    ...frame3,
  },
  frame4: {
    ...frame4,
  },
};
