<template>
  <div class="framecontainer">
    <div class="title-text-box">
      <div class="bigtext">{{ $t("frame4.bigTitle") }}</div>
      <div class="smalltext" v-html="$t('frame4.smallTitle')"></div>
    </div>
    <div class="middlearea">
      <img src="@/assets/images/Img/i14P.webp" class="phoneimg" />
      <div class="phonetext" v-html="$t('frame4.phoneText')"></div>
      <img src="@/assets/images/Img/bigpic/1.png" class="img1" />
      <img src="@/assets/images/Img/bigpic/2.png" class="img2" />
      <img src="@/assets/images/Img/bigpic/3.png" class="img3" />
      <img src="@/assets/images/Img/bigpic/4.png" class="img4" />
      <img src="@/assets/images/Img/bigpic/5.png" class="img5" />
      <img src="@/assets/images/Img/bigpic/6.png" class="img6" />
      <img src="@/assets/images/Img/bigpic/7.png" class="img7" />
    </div>
    <div class="countarea">
      <div class="group" v-for="(element, index) in numberData" :key="index">
        <div class="numbertext" :class="element.color">{{ element.count }}</div>
        <div class="text">{{ $t(element.text) }}</div>
      </div>
    </div>
    <div class="footer">
      <div class="imagebox">
        <img src="@/assets/images/Img/Footer.svg" class="image" />
      </div>
      <div class="copyrightext">{{ site + $t("frame4.footerText") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameComponent4",
  data() {
    return {
      numberData: [
        {
          count: 12,
          text: "frame4.imageText1",
          color: "blue",
        },
        {
          count: 1219,
          text: "frame4.imageText2",
          color: "green",
        },
        {
          count: 134,
          text: "frame4.imageText3",
          color: "red",
        },
        {
          count: 19337136,
          text: "frame4.imageText4",
          color: "yellow",
        },
      ],
      site: glob.site,
    };
  },
};
</script>

<style lang="scss" scoped>
.framecontainer {
  width: 100%;
  height: 100%;
  position: relative;
  .title-text-box {
    width: 100%;
    max-height: 25%;
    margin: 144px 0 32px;
    @include flex-set($align: unset);
    text-align: center;
    .bigtext {
      width: 100%;
      margin-bottom: 15px;
      @include font-set(36px, $black);
    }
    .smalltext {
      width: 100%;
      @include font-set(18px, #3d3d3d);
      line-height: 25px;
    }
  }
  .middlearea {
    width: 100%;
    @include flex-set();
    position: relative;
    margin: 0 auto;
    .phoneimg {
      width: 197px;
      height: 400px;
    }
    .phonetext {
      position: absolute;
      top: 66px;
      @include font-set(14px);
      line-height: 20px;
    }
    .img1 {
      width: 300px;
      height: 202px;
      position: absolute;
      top: 180px;
    }
    .img2 {
      width: 200px;
      height: 135px;
      position: absolute;
      //   top: 215px;
      //   left: 670px;
      transform: translateX(-190px) translateY(80px);
    }
    .img3 {
      width: 200px;
      height: 135px;
      position: absolute;
      //   top: 215px;
      //   right: 670px;
      transform: translateX(190px) translateY(80px);
    }
    .img4 {
      width: 150px;
      height: 100px;
      position: absolute;
      //   top: 200px;
      //   left: 553px;
      transform: translateX(-335px) translateY(50px);
    }
    .img5 {
      width: 150px;
      height: 100px;
      position: absolute;
      //   top: 200px;
      //   right: 553px;
      transform: translateX(335px) translateY(50px);
    }
    .img6 {
      width: 120px;
      height: 80px;
      position: absolute;
      //   top: 180px;
      //   left: 453px;
      transform: translateX(-445px) translateY(20px);
    }
    .img7 {
      width: 120px;
      height: 80px;
      position: absolute;
      //   top: 180px;
      //   right: 453px;
      transform: translateX(445px) translateY(20px);
    }
  }
  .countarea {
    margin: 64px auto 0;
    width: 45%;
    @include flex-set();
    .group {
      margin-right: 64px;
      &:last-child {
        margin-right: unset;
      }
      .numbertext {
        @include font-set(42px, $weight: 900);
        height: 59px;
      }
      .text {
        @include font-set(14px, $black);
      }
      .blue {
        color: #2e87c9;
      }
      .green {
        color: #69b837;
      }
      .red {
        color: #c8221d;
      }
      .yellow {
        color: #f3c01a;
      }
    }
  }
  .footer {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    background: #3d3d3d;
    .imagebox {
      margin: 32px 0 32px;
      width: 100%;
      @include flex-set();
      .image {
        width: 547px;
        height: 100px;
      }
    }
    .copyrightext {
      @include font-set(14px);
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .framecontainer {
    .title-text-box {
      margin: 114px 0 0;
    }
    .middlearea {
      transform: scale(0.9);
      .phoneimg {
        // width: 164px;
        // height: 333px;
      }
    }
    .countarea {
      margin: 16px auto 0;
    }
    .footer {
      height: 160px;
      .imagebox {
        margin: 16px 0 16px;
      }
      .copyrightext {
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .framecontainer {
    .title-text-box {
      margin: 98px 0 -48px;
      .bigtext {
        margin-bottom: 7px;
      }
    }
    .middlearea {
      transform: scale(0.7);
      .phoneimg {
        // width: 164px;
        // height: 333px;
      }
      .phonetext {
        transform: scale(1.25);
      }
    }
    .countarea {
      width: 55%;
      margin: -32px auto 0;
    }
  }
}
</style>
