export default {
  bigTitle: "专业包网平台供应商",
  smallTitle: "在线游戏接入 全球托管服务",
  nineSpecialPointText: "九大特性",
  barText1: "支持丰富的第三方支付",
  barText2: "两名客服轻松操作的运营流程",
  barText3: "数据实时备份，100%自动化防灾",
  barText4: "操作简单，数据精准",
  barText5: "同步支援全平台装置",
  barText6: "手机移动端操作一样简单快捷",
  barText7: "不断扩充的在线游戏类型",
  barText8: "强大的外部数据API接口",
  barText9: "在线客服，7x24全天候服务",
  bottomTitle: "免费接入，程序开发只需1天",
  bottomMediumGroupBigText1: "全天服务",
  bottomMediumGroupSmallText1: "即刻拥有全球顶级优秀资源",
  bottomMediumGroupBigText2: "超多游戏",
  bottomMediumGroupSmallText2: "坐拥真人+体育+彩票+游艺",
  bottomMediumGroupBigText3: "数据在手",
  bottomMediumGroupSmallText3: "玩家、代理数据自我掌控",
  card1: {
    title: "快速",
    description: "SSD数据同步技术",
    text1: "游戏快",
    text2: "存提快",
    text3: "访问快",
  },
  card2: {
    title: "巨省",
    description: "免费接入 一天开站",
    text1: "全天服务",
    text2: "超多游戏",
    text3: "数据在手",
  },
  card3: {
    title: "安全",
    description: "云端漫游技术<br>拒绝黑客保护数据",
    text1: "128位加密",
    text2: "云盾防御",
    text3: "技术服务",
  },
};
