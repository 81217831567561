export default {
  smallTitle: "多面相商務合作",
  bigTitle: "一站式全盤接入服務",
  featureText: "特性",
  smallFeatureText1: "提供博彩平台所需的建制和管理",
  smallFeatureText2: "完美支持iOS/安卓系統",
  smallFeatureText3: "實時數據提供幫助運營商開展業務",
  smallFeatureText4: "管理交易服務為博彩運營商降低交易風險",
  smallFeatureText5: "直播頻道每年超過10,000場現場直播",
  smallFeatureText6: "不斷擴充在線遊戲類型讓您增加收入並帶來新的收入來源",
  smallFeatureText7: "支援多種金流及第三方支付渠道",
  smallFeatureText8: "128位元加密，智能防禦DDOS攻擊，安全有保障",
  smallFeatureText9: "提供7X24 全天候客服，簡省運營商人力成本",
  smallFeatureText10: "HTML5響應式網站",
  smallFeatureText11: "靈活配置的三級代理功能",
  smallFeatureText12: "先進提成係數",
  smallFeatureText13: "自動運行可無人值守",
  circleText1: "運營前台及後台管理系統模組化",
  circleText2: "全平台<br>系統支援",
  circleText3: "網路安全<br>防護管理",
  circleText4: "自主研發<br>安全可靠",
  circleText5: "24小時<br>專業客服",
  circleText6: "多元化遊戲",
  bigCircleText: "運營管理<br>同步擁有",
  processTitle: "簡單的申請流程 24小時內開通 一個帳號全平台同步支援",
  applyAccountText: "申請帳號",
  getApiText: "取得API後端管理平台",
  startOperateText: "開始運營",
};
