export default {
  smallTitle: "多面相商务合作",
  bigTitle: "一站式全盘接入服务",
  featureText: "特性",
  smallFeatureText1: "提供博彩平台所需的建制和管理",
  smallFeatureText2: "完美支持iOS/安卓系统",
  smallFeatureText3: "实时数据提供帮助运营商开展业务",
  smallFeatureText4: "管理交易服务为博彩运营商降低交易风险",
  smallFeatureText5: "直播频道每年超过10,000场现场直播",
  smallFeatureText6: "不断扩充在线游戏类型让您增加收入并带来新的收入来源",
  smallFeatureText7: "支援多种金流及第三方支付渠道",
  smallFeatureText8: "128位元加密，智能防御DDOS攻击，安全有保障",
  smallFeatureText9: "提供7X24 全天候客服，简省运营商人力成本",
  smallFeatureText10: "HTML5响应式网站",
  smallFeatureText11: "灵活配置的三级代理功能",
  smallFeatureText12: "先进提成系数",
  smallFeatureText13: "自动运行可无人值守",
  circleText1: "运营前台及后台管理系统模组化",
  circleText2: "全平台<br>系统支援",
  circleText3: "网路安全<br>防护管理",
  circleText4: "自主研发<br>安全可靠",
  circleText5: "24小时<br>专业客服",
  circleText6: "多元化游戏",
  bigCircleText: "运营管理<br>同步拥有",
  processTitle: "简单的申请流程 24小时内开通 一个帐号全平台同步支援",
  applyAccountText: "申请帐号",
  getApiText: "取得API后端管理平台",
  startOperateText: "开始运营",
};
