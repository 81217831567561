export default {
  bigTitle: "满足使用者所需是唯一的原则",
  smallTitle: "运用最新技术和灵活的创意<br>为您提供最快速便捷的游戏包网服务",
  phoneText: "手机版介面<br>震撼来袭<br>让您随时操控<br>玩转投注不设限",
  imageText1: "全球合作机构数量",
  imageText2: "运营总带宽",
  imageText3: "服务的客户",
  imageText4: "全球会员数",
  footerText: " Copyright © 2022 版权所有. All rights reserved.",
};
