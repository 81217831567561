<template>
  <div class="navbarcontainer">
    <div class="nav" :class="navbarClass">
      <div class="nav_box">
        <div class="left">
          <img :src="logo" class="logo" @click="scrollToFrame(0)" />
        </div>
        <div class="right">
          <div class="frametext" @click="scrollToFrame(0)">
            {{ $t("navbar.frame1") }}
          </div>
          <div class="frametext" @click="scrollToFrame(1)">
            {{ $t("navbar.frame2") }}
          </div>
          <div class="frametext" @click="scrollToFrame(2)">
            {{ $t("navbar.frame3") }}
          </div>
          <div class="frametext" @click="scrollToFrame(3)">
            {{ $t("navbar.frame4") }}
          </div>
          <div class="frametext link" @click="freeplay()">
            {{ $t("navbar.freeplay") }}
          </div>
          <img
            src="@/assets/images/Icon/Contact.svg"
            class="icon"
            @click="openContact"
          />
          <div class="langBtnGroup">
            <img
              :src="langs[currentLangIndex].image"
              class="icon righticon"
              @click="clickLangBtn()"
            />
            <el-select
              class="select hover"
              v-model="$i18n.locale"
              @change="handleClickLan($i18n.locale)"
              ref="select"
              @visible-change="visibleChange"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="({ value, image }, i) in langs"
                :key="`Lang${i}`"
                :value="value"
              >
                <img :src="image" class="img" />
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <contactDialogComponent ref="contactDialogComponent" />
  </div>
</template>

<script>
import contactDialogComponent from "@/components/ContactDialogComponent.vue";

export default {
  name: "NavbarComponent",
  props: {
    navbarClass: { String },
  },
  data() {
    return {
      flag: false,
      langs: [
        {
          value: "zht",
          name: "繁體中文",
          image: require("@/assets/images/flags/L/HK.svg"),
        },
        {
          value: "zhs",
          name: "简体中文",
          image: require("@/assets/images/flags/L/CN.svg"),
        },
        {
          value: "eng",
          name: "English",
          image: require("@/assets/images/flags/L/US.svg"),
        },
      ],
      currentLangIndex: 0,
      currentLang: "zht",
      logo: require(`@/assets/images/${glob.logo}`),
    };
  },
  components: {
    contactDialogComponent,
  },
  watch: {
    currentLang() {
      this.checkLang();
    },
  },
  mounted() {
    if (localStorage.getItem("locale")) {
      this.currentLang = localStorage.getItem("locale");
      this.$i18n.locale = this.currentLang;
    } else {
      localStorage.setItem("locale", this.currentLang);
      this.$i18n.locale = this.currentLang;
    }
  },
  methods: {
    scrollToFrame(page) {
      this.$emit("scrollToFrame", page);
    },
    openContact() {
      this.$refs.contactDialogComponent.$emit("open");
    },
    handleClickLan(lan) {
      this.$i18n.locale = lan;
      localStorage.setItem("locale", lan);
      this.currentLang = lan;
    },
    clickLangBtn() {
      // console.log(this.flag);
      if (this.flag == false) {
        this.$refs.select.$el.click();
      }
    },
    visibleChange(flag) {
      // console.log("visibleChange", flag);
      setTimeout(() => {
        this.flag = flag;
      }, 10);
    },
    checkLang() {
      for (let i = 0; i < this.langs.length; i++) {
        if (this.currentLang == this.langs[i].value) {
          this.currentLangIndex = i;
        }
      }
    },
    freeplay() {
      window.open("https://mgp.bz/");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbarcontainer {
  width: 100%;
  .nav {
    @include flex-set();
    width: 100%;
    margin: 0 auto;
    height: 80px;
    position: fixed;
    z-index: 100;
    transition: all 300ms ease-in-out;
    .nav_box {
      @include flex-set();
      max-width: 75%;
      width: 90%;
      height: 100%;
      .left {
        width: 35%;
        height: 100%;
        @include flex-set($justify: unset);
        .logo {
          height: 42px;
          cursor: pointer;
        }
      }
      .right {
        width: 65%;
        height: 100%;
        @include flex-set($justify: flex-end);
        .frametext {
          @include font-set(16px);
          margin-right: 32px;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
            transition: all 200ms ease-in-out;
          }
        }
        .link {
          @include font-set(16px, #efc763);
        }
        .icon {
          width: 32px;
          height: 32px;
          cursor: pointer;
          margin-left: 32px;
          &:hover {
            transform: scale(1.1);
            transition: all 200ms ease-in-out;
          }
        }
        // .righticon {
        //   margin-left: 32px;
        // }
        .langBtnGroup {
          position: relative;
          @include flex-set();
          .select {
            height: 0;
            width: 0;
            overflow: hidden;
            position: absolute;
            bottom: 20px;
            right: 30px;
            :deep(.el-input__inner) {
              height: 0;
              width: 0;
            }
          }
        }
      }
    }
  }
  :deep(.el-select-dropdown__item) {
    @include flex-set();
  }
  .frame1 {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .frame2 {
    background-color: #000000;
  }
  .frame3 {
    background-color: #3d3d3d;
  }
}
</style>
