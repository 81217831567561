<template>
  <div class="framecontainer">
    <div class="introduce-text">
      <div class="up-text">{{ $t("frame1.mediumText") }}</div>
      <div class="bottom-text">{{ $t("frame1.bigText") }}</div>
    </div>
    <div class="squarebox">
      <div class="square" v-for="(current, index) in squareData" :key="index">
        <div class="icon_text">
          <img :src="current.img" class="icon" />
          <div class="text">{{ $t(current.text) }}</div>
        </div>
        <div class="background" :class="`square${index + 1}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameComponent1",
  data() {
    return {
      squareData: [
        {
          img: require("@/assets/images/Icon/P1/01.svg"),
          text: "frame1.squareText1",
        },
        {
          img: require("@/assets/images/Icon/P1/02.svg"),
          text: "frame1.squareText2",
        },
        {
          img: require("@/assets/images/Icon/P1/03.svg"),
          text: "frame1.squareText3",
        },
        {
          img: require("@/assets/images/Icon/P1/04.svg"),
          text: "frame1.squareText4",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.framecontainer {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/Img/macao.jpg");
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.5) 50%
    ),
    url("~@/assets/images/Img/macao.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include flex-set($align: unset);
  .introduce-text {
    margin-top: 18%;
    width: 100%;
    @include flex-set();
    .up-text {
      width: 100%;
      @include font-set(48px, $weight: 500);
      margin-bottom: 24px;
    }
    .bottom-text {
      width: 100%;
      @include font-set(72px, $weight: 500);
    }
  }
  .squarebox {
    @include flex-set();
    width: 100%;
    height: 50%;
    .square {
      height: 117px;
      width: 117px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
      .icon_text {
        z-index: 100;
        @include flex-set();
        width: 70px;
        height: 80px;
        .icon {
          width: 50px;
          height: 50px;
          margin-bottom: 8px;
        }
        .text {
          height: 22px;
          line-height: 22px;
          @include font-set(16px);
        }
      }
      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
        border-radius: 10px;
      }
      .square1 {
        z-index: 40;
        background-color: #2e87c9;
      }
      .square2 {
        z-index: 30;
        background-color: #69b837;
      }
      .square3 {
        z-index: 20;
        background-color: #c8221d;
      }
      .square4 {
        z-index: 10;
        background-color: #f3c01a;
      }
    }
  }
}
</style>
