export default {
  bigTitle: "專業包網平台供應商",
  smallTitle: "在線遊戲接入 全球託管服務",
  nineSpecialPointText: "九大特性",
  barText1: "支持豐富的第三方支付",
  barText2: "兩名客服輕鬆操作的運營流程",
  barText3: "數據實時備份，100%自動化防災",
  barText4: "操作簡單，數據精準",
  barText5: "同步支援全平台裝置",
  barText6: "手機移動端操作一樣簡單快捷",
  barText7: "不斷擴充的在線遊戲類型",
  barText8: "強大的外部數據API接口",
  barText9: "在線客服，7x24全天候服務",
  bottomTitle: "免費接入，程序開發只需1天",
  bottomMediumGroupBigText1: "全天服務",
  bottomMediumGroupSmallText1: "即刻擁有全球頂級優秀資源",
  bottomMediumGroupBigText2: "超多遊戲",
  bottomMediumGroupSmallText2: "坐擁真人+體育+彩票+遊藝",
  bottomMediumGroupBigText3: "數據在手",
  bottomMediumGroupSmallText3: "玩家、代理數據自我掌控",
  card1: {
    title: "快速",
    description: "SSD數據同步技術",
    text1: "遊戲快",
    text2: "存提快",
    text3: "訪問快",
  },
  card2: {
    title: "巨省",
    description: "免費接入 一天開站",
    text1: "全天服務",
    text2: "超多遊戲",
    text3: "數據在手",
  },
  card3: {
    title: "安全",
    description: "雲端漫遊技術<br>拒絕黑客保護數據",
    text1: "128位加密",
    text2: "雲盾防禦",
    text3: "技術服務",
  },
};
