<template>
  <div class="framecontainer">
    <div class="title-text-box">
      <div class="smalltext">{{ $t("frame2.smallTitle") }}</div>
      <div class="bigtext">{{ $t("frame2.bigTitle") }}</div>
    </div>
    <div class="bottomarea">
      <div class="introducebox">
        <div class="left">
          <div class="title">{{ $t("frame2.featureText") }}</div>
          <div class="textarea">
            <div class="text" v-for="index in 13" :key="index">
              {{ $t(`frame2.smallFeatureText${index}`) }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="circlegroup">
            <div class="group">
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText1')"></div>
                </div>
              </div>
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText2')"></div>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText3')"></div>
                </div>
              </div>
              <div class="circlebox">
                <div class="bigcircle">
                  <div class="text" v-html="$t('frame2.bigCircleText')"></div>
                </div>
              </div>
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText4')"></div>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText5')"></div>
                </div>
              </div>
              <div class="circlebox">
                <div class="circle">
                  <div class="text" v-html="$t('frame2.circleText6')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="process_box">
            <div class="title">
              {{ $t("frame2.processTitle") }}
            </div>
            <div class="image_text_group">
              <img src="@/assets/images/Icon/P2/Step1.svg" class="img" />
              <div class="text">{{ $t("frame2.applyAccountText") }}</div>
            </div>
            <div class="arrow_group">
              <img src="@/assets/images/Icon/P2/StepArrow.svg" class="img" />
            </div>
            <div class="image_text_group">
              <img src="@/assets/images/Icon/P2/Step2.svg" class="img" />
              <div class="text">{{ $t("frame2.getApiText") }}</div>
            </div>
            <div class="arrow_group">
              <img src="@/assets/images/Icon/P2/StepArrow.svg" class="img" />
            </div>
            <div class="image_text_group">
              <img src="@/assets/images/Icon/P2/Step3.svg" class="img" />
              <div class="text">{{ $t("frame2.startOperateText") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameComponent2",
  data() {
    return {
      length: 58,
      sqrt3: Math.sqrt(3),
      translateX: [2, 1, -1, -2, -1, 1],
      translateY: [0, -1, -1, 0, 1, 1],
    };
  },
};
</script>

<style lang="scss" scoped>
.framecontainer {
  background-color: #3d3d3d;
  width: 100%;
  height: 100%;
  .title-text-box {
    width: 100%;
    max-height: 25%;
    margin: 144px 0 64px;
    @include flex-set($align: unset);
    text-align: center;
    .smalltext {
      width: 100%;
      @include font-set(18px, #f0c856);
    }
    .bigtext {
      width: 100%;
      margin-top: 15px;
      @include font-set(36px, #f0c856);
    }
  }
  .bottomarea {
    width: 100%;
    height: 75%;
    @include flex-set($align: unset, $justify: space-around);
    .introducebox {
      width: 58%;
      @include flex-set($align: unset);
      .left {
        width: 50%;
        .title {
          width: 100%;
          text-align: left;
          margin-bottom: 16px;
          @include font-set(36px, #f0c856);
        }
        .textarea {
          width: 100%;
          @include flex-set($align: unset);
          .text {
            width: 100%;
            height: 33px;
            line-height: 33px;
            text-align: left;
            @include font-set(18px);
          }
        }
      }
      .right {
        width: 50%;
        .circlegroup {
          width: 89%;
          @include flex-set($align: unset);
          margin: 0 auto 30px;
          .group {
            width: 100%;
            @include flex-set();
            .circlebox {
              padding: 0 5px;
              .circle {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                background-color: #f0c856;
                @include flex-set();
                .text {
                  width: 90%;
                  @include font-set(14px, #3d3d3d);
                  line-height: 20px;
                }
              }
              .bigcircle {
                height: 142px;
                width: 142px;
                border-radius: 50%;
                background-color: #fff;
                @include flex-set();
                .text {
                  width: 90%;
                  @include font-set(21px, #f0c856);
                  line-height: 28px;
                }
              }
            }
          }
        }
        .process_box {
          width: 100%;
          max-width: 510px;
          margin: 0 auto;
          height: 131px;
          @include flex-set();
          border-radius: 10px;
          border: #f0c856 2px solid;
          position: relative;
          .title {
            position: absolute;
            top: -14px;
            width: 75%;
            background-color: #f0c856;
            @include font-set(14px, $black);
            line-height: 20px;
            text-align: center;
            border-radius: 10px;
            min-width: 378px;
          }
          .image_text_group {
            width: 116px;
            .img {
              width: 60px;
              height: 60px;
            }
            .text {
              margin-top: 8px;
              width: 100%;
              text-align: center;
              @include font-set(12px);
            }
          }
          .arrow_group {
            width: 50px;
            .img {
              width: 17px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .framecontainer {
    .title-text-box {
      margin: 112px 0 32px;
    }
    .bottomarea {
      .introducebox {
        width: 70%;
        .left {
          .title {
          }
          .textarea {
            .text {
            }
          }
        }
        .right {
          .circlegroup {
            .group {
              .circlebox {
                .circle {
                  height: 100px;
                  width: 100px;
                }
                .bigcircle {
                  height: 122px;
                  width: 122px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
